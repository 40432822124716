// Onboarding
export const ONBOARDING_ROOT = '/onboarding';
export const GOOGLE_ONBOARDING_ROOT = '/google-onboarding';
export const IQVIA_ONBOARDING_ROOT = '/iqvia-onboarding';
export const VERIFY_ONBOARDING_LINK = '/onboarding/verifyOnboardingLink';
export const VALIDATE_EMAIL = '/user/validEmail';
export const VALIDATE_WORK_EMAIL = '/user/validWorkEmail';
export const VALIDATE_LICENSE_NUMBER = '/user/validLicenseNumber';

// Notifications Endpoints
export const ALL_NOTIFICATIONS = '/notifications';
export const UPDATE_NOTIFICATIONS_READ_STATUS = '/notifications/read';

// AdBoards Endpoints
export const ADBOARDS = '/ad-boards';

// Video Endpoints
export const VIDEOS_ENDPOINT = '/videos';
export const VIDEO_COMMENTS_ENDPOINT = '/video-comments';

// Attachments Endpoints
export const ATTACHMENTS_ENDPOINT = '/attachments';
