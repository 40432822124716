import React, { useEffect, useState } from 'react';

export const IQVIASignIn = ({
  redirectUrl,
  loginCallback,
}: {
  redirectUrl: string;
  loginCallback: (account: any) => void;
}) => {
  const [isIqviaButtonLoaded, setIsIqviaButtonLoaded] = useState(false);

  useEffect(() => {
    if (!isIqviaButtonLoaded) {
      window.hcaSdk.displayActiveButtons();
      setIsIqviaButtonLoaded(true);
    }
  }, [isIqviaButtonLoaded]);

  window.hcaSdk
    .setHcaSdkConfig(
      process.env.REACT_APP_IQVIA_LOGIN_CLIENT_ID,
      true,
      false,
      ['https://auth.onekeyconnect.com/x/profile.basic'],
      ['auth.onekeyconnect.com'],
      'auth.onekeyconnect.com',
      'b2c_1a_hca_signup_signin',
      'b2c_1a_hca_signuponly',
      '',
      'https://apim-prod-westeu-onekey.azure-api.net/api/hca/user/me',
      '/expired-link',
      redirectUrl,
    )
    .finally(() => {
      window.hcaSdk.displayActiveButtons();
      window.hcaSdk.setLabels('Sign in with IQVIA OneKey');
    });

  window.hcaSdk.setLocaleParams('en-US');
  window.hcaSdk.setLoginCallBack(loginCallback);

  return <div id="hca_signin"></div>;
};
