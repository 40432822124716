import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ConfettiExplosion, { ConfettiProps } from 'react-confetti-explosion';
import { FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import BlueTier from '../../images/Blue-Tier.png';
import SilverTier from '../../images/Silver-Tier.png';
import GoldTier from '../../images/Gold-Tier.png';
import PlatinumTier from '../../images/Platinum-Tier.png';
import { RewardsTierDto } from '../../dto/rewards.dto';
import { axiosCall } from '../../utils/api';
import { UserContext } from '../../UserContext';
import { RewardTierNames } from '../../pages/Rewards/data';
import { BasicModal } from '@keyops-hcp/ui-components';

const confettiProps: ConfettiProps = {
  force: 0.6,
  duration: 2500,
  particleCount: 100,
  width: 1000,
  zIndex: 1400,
  style: { left: '50%' },
  colors: ['#3376D1', '#2C5282', '#EBF8FF', '#DFFF00', '#FFFF00'],
};
interface NewTierContentProps {
  tier: string;
  dismissNotificationPopUp: () => void;
  closeNotificationPopUp: () => void;
  openClaimRewardsPopUpFn: () => void;
}

const NewTierContent = ({
  tier,
  dismissNotificationPopUp,
  closeNotificationPopUp,
  openClaimRewardsPopUpFn,
}: NewTierContentProps): React.JSX.Element => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const { t } = useTranslation();

  const [clickedLater, setClickedLater] = useState(false);
  const [isConfettiExploding, setIsConfettiExploding] = useState(false);

  useEffect(() => {
    setIsConfettiExploding(true);
  }, []);

  const tierBadge = () => {
    switch (tier) {
      case 'silver':
        return SilverTier;
      case 'gold':
        return GoldTier;
      case 'platinum':
        return PlatinumTier;
      default:
        return BlueTier;
    }
  };

  return (
    <>
      <Stack
        sx={{
          textAlign: 'center',
        }}
        alignItems="center"
        justifyContent="center"
        mb={{ xs: clickedLater && 6, sm: clickedLater && 4 }}
      >
        <img src={tierBadge()} width={103} height={103} alt={tier} />
        <Box>
          {isConfettiExploding && <ConfettiExplosion {...confettiProps} />}
        </Box>
        <Typography
          aria-label="share"
          variant={'h6'}
          component={'h2'}
          fontSize={'1.125rem'}
          fontWeight={'600'}
          color={palette.keyops.gray[700]}
          my={2}
        >
          {t(`rewards.newRewardTierPopUp.newTier.${tier}.title`)}
        </Typography>
        <Typography variant={'body1'} color={palette.keyops.gray[700]}>
          {clickedLater
            ? t('rewards.newRewardTierPopUp.newTier.clickedLaterBodyText')
            : t(`rewards.newRewardTierPopUp.newTier.${tier}.body`)}
        </Typography>
      </Stack>
      {!clickedLater && (
        <Stack
          direction={'row'}
          alignItems={isMobile ? 'center' : 'right'}
          justifyContent={isMobile ? 'center' : 'right'}
          gap={2}
          mt={3}
          mb={{ xs: 3, sm: 0 }}
        >
          <Button
            variant="outlined"
            sx={{
              fontSize: 16,
              textTransform: 'none',
            }}
            onClick={() => {
              dismissNotificationPopUp();
              setClickedLater(true);
            }}
          >
            {t('rewards.newRewardTierPopUp.laterButton')}
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={{
              fontSize: 16,
              textTransform: 'none',
            }}
            onClick={() => {
              openClaimRewardsPopUpFn();
              dismissNotificationPopUp();
              closeNotificationPopUp();
            }}
          >
            {t('rewards.newRewardTierPopUp.claimRewardButton')}
          </Button>
        </Stack>
      )}
    </>
  );
};

type NewTierNotificationPopUpProps = {
  rewardTierData: RewardsTierDto;
  openClaimRewardsPopUpFn: () => void;
};

const NewTierNotificationPopUp = ({
  rewardTierData,
  openClaimRewardsPopUpFn,
}: NewTierNotificationPopUpProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const context = useContext(UserContext);
  const [popUpDelay, setPopUpDelay] = useState(true);
  const [openModal, setOpenModal] = useState(
    !rewardTierData.notificationDismissed,
  );

  useEffect(() => {
    setTimeout(() => {
      setPopUpDelay(false);
    }, 1200);
  }, []);

  const closePopUp = () => setOpenModal(false);
  const dismissPopUp = async () => {
    try {
      await axiosCall(
        false,
        'post',
        'user/dismiss-reward-tier-notification',
        false,
        {
          rewardTierId: rewardTierData.id,
        },
      );
      context.updateRewardsTierData();
    } catch (e) {
      console.log(`Error dismissing notification: ${e}`);
    }
  };

  const handleClosePopUp = () => {
    dismissPopUp();
    closePopUp();
  };

  if (
    rewardTierData.notificationDismissed ||
    rewardTierData.claimed ||
    rewardTierData.tier === RewardTierNames.blue ||
    popUpDelay
  )
    return undefined;

  if (isMobile) {
    return (
      <Drawer
        anchor={'bottom'}
        open={openModal}
        onClose={handleClosePopUp}
        PaperProps={{
          sx: {
            minHeight: '220px',
            padding: '16px',
            borderRadius: '8px 8px 0 0',
          },
        }}
      >
        <Box display={'flex'} flexDirection={'row-reverse'}>
          <FiX onClick={handleClosePopUp} fontSize={24} />
        </Box>
        <NewTierContent
          tier={rewardTierData.tier}
          dismissNotificationPopUp={dismissPopUp}
          closeNotificationPopUp={closePopUp}
          openClaimRewardsPopUpFn={openClaimRewardsPopUpFn}
        />
      </Drawer>
    );
  }

  return (
    <BasicModal open={openModal} handleClose={handleClosePopUp}>
      <NewTierContent
        tier={rewardTierData.tier}
        dismissNotificationPopUp={dismissPopUp}
        closeNotificationPopUp={closePopUp}
        openClaimRewardsPopUpFn={openClaimRewardsPopUpFn}
      />
    </BasicModal>
  );
};

export default NewTierNotificationPopUp;
