import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ONBOARDING_LANDING_PATH } from '../../utils/routes';
import Loading from '../../components/Loading';

export const IqviaOnboardingRedirect = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const onboardingId = localStorage.getItem('linkId');
    const link = `${ONBOARDING_LANDING_PATH}/${onboardingId}?${searchParams.toString()}`;
    if (onboardingId) {
      navigate(link);
    }
  }, []);

  return <Loading size="5rem" pt={5} />;
};
