import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { Outlet } from 'react-router-dom';

import {
  ReactQueryClientProvider,
  KeyOpsThemeProvider,
  analytics,
} from '@keyops-hcp/ui-components';

import './languages/i18n.config';
import { UserContextProvider } from './UserContext';

// HOC
import { ContactUIProvider } from './higher-order-components/ContactUIContext';
import ContactComponent from './higher-order-components/ContactUIContext/ContactComponent';
import { SurveyContextProvider } from './higher-order-components/AppLayout/SurveyContext';
import { AuthProvider } from './higher-order-components/useAuth';

// components
import { ToastProvider } from './components/Toast/ToastProvider';
import Toast from './components/Toast';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hcaSdk: any;
  }
}

window.hcaSdk = window.hcaSdk || {};

const App = () => {
  useEffect(() => {
    analytics.init();
    localStorage.removeItem('token'); // remove admin token if present
  }, []);

  return (
    <ReactQueryClientProvider>
      <KeyOpsThemeProvider>
        {/* Languages provider */}
        <I18nextProvider i18n={i18next}>
          <ToastProvider>
            <AuthProvider>
              <UserContextProvider>
                <ContactUIProvider>
                  <ContactComponent />
                  <SurveyContextProvider>
                    <Toast />
                    <Outlet />
                  </SurveyContextProvider>
                </ContactUIProvider>
              </UserContextProvider>
            </AuthProvider>
          </ToastProvider>
        </I18nextProvider>
      </KeyOpsThemeProvider>
    </ReactQueryClientProvider>
  );
};

export default App;
