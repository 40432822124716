import { NurseHospitalSetting } from '@keyops-hcp/constants';
import {
  PhysicianDetails,
  PharmacistDetails,
  NurseDetails,
  OnboardingSaveState,
} from '@keyops-hcp/dtos';
import i18next from '../../../languages/i18n.config';

const tOnboardingForm = (key: string): string =>
  i18next.t(`onboarding.forms.${key}`);

type LandingPageInputs = {
  'continue-with-google'?: string;
  'continue-with-iqvia'?: string;
  email: string;
  password: string;
  linkId: string;
  referralId: string;
  adBoardInviteId: string;
  termsOfService: boolean;
  privacyPolicy: boolean;
};
type AccountDetailsFormInputs = {
  firstName: string;
  lastName: string;
  country: string;
  provinceOfPractice: string;
  cityOfPractice: string;
};
type PersonalDetailsFormInputs = {
  dateOfBirth: string;
  gender: string;
  ethnicity: string[];
};
type PhysicianDetailsFormInputs = PhysicianDetails & {
  specialtyFields: { specialty: string; subSpecialty?: string[] }[];
};
type NurseDetailsFormInputs = NurseDetails & {
  hospitalSetting: NurseHospitalSetting;
};

type PrefixKeys<T, Prefix extends string> = {
  [K in keyof T as `${Prefix}.${string & K}`]: T[K];
};

type PracticeDetailsFormInputs = PrefixKeys<
  PhysicianDetailsFormInputs,
  'physician'
> &
  PrefixKeys<PharmacistDetails, 'pharmacist'> &
  PrefixKeys<NurseDetailsFormInputs, 'nurse'>;

type EngageWithKeyopsFormInputs = {
  engagementPreference: string[];
  involvementCommittee: boolean;
  smsNotifications: boolean;
  mobileNumber: string;
};

type saveState = Omit<
  OnboardingSaveState,
  'hcpProfile' | 'yearOfBirth' | 'languagePreference'
> & {
  'continue-with-google'?: string;
  'continue-with-iqvia'?: string;
};

export type FormInputs = saveState & PracticeDetailsFormInputs;

type FormField<K extends keyof FormInputs> = {
  name: K;
  label?: string;
};

type FormFields = {
  landingPage: {
    [K in keyof LandingPageInputs]: FormField<K>;
  };
  accountDetails: {
    [K in keyof AccountDetailsFormInputs]: FormField<K>;
  };
  personalDetails: {
    [K in keyof PersonalDetailsFormInputs]: FormField<K>;
  };
  practiceDetails: {
    hcpType: FormField<'hcpType'>;
    physician: {
      [K in keyof PhysicianDetailsFormInputs]: FormField<`physician.${K}`>;
    };
    pharmacist: {
      [K in keyof PharmacistDetails]: FormField<`pharmacist.${K}`>;
    };
    nurse: {
      [K in keyof NurseDetailsFormInputs]: FormField<`nurse.${K}`>;
    };
  };
  engageWithKeyops: {
    [K in keyof EngageWithKeyopsFormInputs]: FormField<K>;
  };
};

export const FORM_FIELDS: FormFields = {
  landingPage: {
    email: {
      name: 'email',
      label: tOnboardingForm('landingPage.formFields.email'),
    },
    password: {
      name: 'password',
      label: tOnboardingForm('landingPage.formFields.password'),
    },
    linkId: {
      name: 'linkId',
    },
    referralId: {
      name: 'referralId',
    },
    adBoardInviteId: {
      name: 'adBoardInviteId',
    },
    termsOfService: {
      name: 'termsOfService',
    },
    privacyPolicy: {
      name: 'privacyPolicy',
    },
  },
  accountDetails: {
    firstName: {
      name: 'firstName',
      label: tOnboardingForm('accountDetails.formFields.firstName'),
    },
    lastName: {
      name: 'lastName',
      label: tOnboardingForm('accountDetails.formFields.lastName'),
    },
    country: {
      name: 'country',
      label: tOnboardingForm('accountDetails.formFields.country'),
    },
    provinceOfPractice: {
      name: 'provinceOfPractice',
    },
    cityOfPractice: {
      name: 'cityOfPractice',
      label: tOnboardingForm('accountDetails.formFields.cityOfPractice'),
    },
  },
  personalDetails: {
    dateOfBirth: {
      name: 'dateOfBirth',
      label: tOnboardingForm('personalDetails.formFields.dateOfBirth'),
    },
    gender: {
      name: 'gender',
      label: tOnboardingForm('personalDetails.formFields.gender'),
    },
    ethnicity: {
      name: 'ethnicity',
      label: tOnboardingForm('personalDetails.formFields.ethnicity'),
    },
  },
  practiceDetails: {
    hcpType: {
      name: 'hcpType',
    },
    // PHYSICIAN FIELDS
    physician: {
      specialtyFields: {
        name: 'physician.specialtyFields',
        label: tOnboardingForm(
          'practiceDetails.formFields.physician.specialtyFields',
        ),
      },
      specialty: {
        name: 'physician.specialty',
        label: tOnboardingForm(
          'practiceDetails.formFields.physician.specialty',
        ),
      },
      subSpecialty: {
        name: 'physician.subSpecialty',
        label: tOnboardingForm(
          'practiceDetails.formFields.physician.subSpecialty',
        ),
      },
      licenseNumber: {
        name: 'physician.licenseNumber',
        label: tOnboardingForm(
          'practiceDetails.formFields.physician.licenseNumber',
        ),
      },
      practiceLicenseYear: {
        name: 'physician.practiceLicenseYear',
        label: tOnboardingForm(
          'practiceDetails.formFields.physician.practiceLicenseYear',
        ),
      },
      practiceSetting: {
        name: 'physician.practiceSetting',
        label: tOnboardingForm(
          'practiceDetails.formFields.physician.practiceSetting',
        ),
      },
      workEmail: {
        name: 'physician.workEmail',
        label: tOnboardingForm(
          'practiceDetails.formFields.physician.workEmail',
        ),
      },
      bypassWorkEmailVerification: {
        name: 'physician.bypassWorkEmailVerification',
        label: tOnboardingForm(
          'practiceDetails.formFields.physician.bypassWorkEmailVerification',
        ),
      },
      otherDetails: {
        name: 'physician.otherDetails',
        label: tOnboardingForm(
          'practiceDetails.formFields.physician.otherDetails',
        ),
      },
    },
    // PHARMACIST FIELDS
    pharmacist: {
      licenseNumber: {
        name: 'pharmacist.licenseNumber',
        label: tOnboardingForm(
          'practiceDetails.formFields.pharmacist.licenseNumber',
        ),
      },
      practiceLicenseYear: {
        name: 'pharmacist.practiceLicenseYear',
        label: tOnboardingForm(
          'practiceDetails.formFields.pharmacist.practiceLicenseYear',
        ),
      },
      practiceSetting: {
        name: 'pharmacist.practiceSetting',
        label: tOnboardingForm(
          'practiceDetails.formFields.pharmacist.practiceSetting',
        ),
      },
      otherPracticeSetting: {
        name: 'pharmacist.otherPracticeSetting',
        label: tOnboardingForm(
          'practiceDetails.formFields.pharmacist.pleaseSpecify',
        ),
      },
      practiceSpecialty: {
        name: 'pharmacist.practiceSpecialty',
        label: tOnboardingForm(
          'practiceDetails.formFields.pharmacist.practiceSpecialty',
        ),
      },
      otherPracticeSpecialty: {
        name: 'pharmacist.otherPracticeSpecialty',
        label: tOnboardingForm(
          'practiceDetails.formFields.pharmacist.pleaseSpecify',
        ),
      },
      retailChain: {
        name: 'pharmacist.retailChain',
        label: tOnboardingForm(
          'practiceDetails.formFields.pharmacist.retailChain',
        ),
      },
      otherRetailChain: {
        name: 'pharmacist.otherRetailChain',
        label: tOnboardingForm(
          'practiceDetails.formFields.pharmacist.pleaseSpecify',
        ),
      },
      pharmacistType: {
        name: 'pharmacist.pharmacistType',
        label: tOnboardingForm(
          'practiceDetails.formFields.pharmacist.pharmacistType',
        ),
      },
      otherDetails: {
        name: 'pharmacist.otherDetails',
        label: tOnboardingForm(
          'practiceDetails.formFields.pharmacist.otherDetails',
        ),
      },
    },
    // NURSE FIELDS
    nurse: {
      occupation: {
        name: 'nurse.occupation',
        label: tOnboardingForm('practiceDetails.formFields.nurse.occupation'),
      },
      otherOccupation: {
        name: 'nurse.otherOccupation',
        label: tOnboardingForm(
          'practiceDetails.formFields.nurse.pleaseSpecify',
        ),
      },
      practiceSetting: {
        name: 'nurse.practiceSetting',
        label: tOnboardingForm(
          'practiceDetails.formFields.nurse.practiceSetting',
        ),
      },
      otherPracticeSetting: {
        name: 'nurse.otherPracticeSetting',
        label: tOnboardingForm(
          'practiceDetails.formFields.nurse.pleaseSpecify',
        ),
      },
      specialty: {
        name: 'nurse.specialty',
        label: tOnboardingForm('practiceDetails.formFields.nurse.specialty'),
      },
      otherSpecialty: {
        name: 'nurse.otherSpecialty',
        label: tOnboardingForm(
          'practiceDetails.formFields.nurse.pleaseSpecify',
        ),
      },
      practiceSettingDetail: {
        name: 'nurse.practiceSettingDetail',
        label: tOnboardingForm(
          'practiceDetails.formFields.nurse.practiceSettingDetail',
        ), // This one has many different labels & will be set dynamically
      },
      hospitalSetting: {
        name: 'nurse.hospitalSetting',
        label: tOnboardingForm(
          'practiceDetails.formFields.nurse.hospitalSetting',
        ),
      },
      otherPracticeSettingDetail: {
        name: 'nurse.otherPracticeSettingDetail',
        label: tOnboardingForm(
          'practiceDetails.formFields.nurse.pleaseSpecify',
        ),
      },
      employmentStatus: {
        name: 'nurse.employmentStatus',
        label: tOnboardingForm(
          'practiceDetails.formFields.nurse.employmentStatus',
        ),
      },
      licenseNumber: {
        name: 'nurse.licenseNumber',
        label: tOnboardingForm(
          'practiceDetails.formFields.nurse.licenseNumber',
        ),
      },
      practiceLicenseYear: {
        name: 'nurse.practiceLicenseYear',
        label: tOnboardingForm(
          'practiceDetails.formFields.nurse.practiceLicenseYear',
        ),
      },
      workEmail: {
        name: 'nurse.workEmail',
        label: tOnboardingForm('practiceDetails.formFields.nurse.workEmail'),
      },
      bypassWorkEmailVerification: {
        name: 'nurse.bypassWorkEmailVerification',
        label: tOnboardingForm(
          'practiceDetails.formFields.nurse.bypassWorkEmailVerification',
        ),
      },
      otherDetails: {
        name: 'nurse.otherDetails',
        label: tOnboardingForm('practiceDetails.formFields.nurse.otherDetails'),
      },
    },
  },
  engageWithKeyops: {
    engagementPreference: {
      name: 'engagementPreference',
      label: tOnboardingForm(
        'engageWithKeyops.formFields.engagementPreference',
      ),
    },
    involvementCommittee: {
      name: 'involvementCommittee',
      label: tOnboardingForm(
        'engageWithKeyops.formFields.involvementCommittee',
      ),
    },
    smsNotifications: {
      name: 'smsNotifications',
      label: tOnboardingForm('engageWithKeyops.formFields.smsNotifications'),
    },
    mobileNumber: {
      name: 'mobileNumber',
      label: tOnboardingForm('engageWithKeyops.formFields.mobileNumber'),
    },
  },
};

type Option = {
  value: string;
  label: string;
};
export type Options = Option[];

export const EngagementsOptions: Options = [
  { value: 'Consulting', label: 'Consulting' },
  { value: 'Advisory boards', label: 'Advisory boards' },
  {
    value: 'Survey creation and results commentary',
    label: 'Survey creation and results commentary',
  },
  {
    value: 'Industry presentations and delivery',
    label: 'Industry presentations and delivery',
  },
];

type BooleanOption = {
  value: boolean;
  label: string;
};
export type BooleanOptionsType = BooleanOption[];

export const BooleanOptions: BooleanOptionsType = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
